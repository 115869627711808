<button mat-raised-button
        [ngClass]="status"
        (click)="toggle()">
    <div>
        <ng-content select="[dropdown-head]"></ng-content>
    </div>
    <div [@expandArrow]="status"
         class="text-center tffl-text-primary-light">
        <div *ngIf="status == 'opened'">
            <i class="fas fa-angle-up"></i>
        </div>
        <div *ngIf="status == 'closed'">
            <i class="fas fa-angle-down"></i>
        </div>
    </div>
    <div [@slideDown]="status"
         style="overflow: hidden;">
        <ng-content select="[dropdown-body]"></ng-content>
    </div>
</button>