import { Inject, Injectable } from '@angular/core';
import { Address } from 'tffl-core';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({ providedIn: 'root' })
export class GoogleStaticMapsService {

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
    }

    getMapSrc(location: Address, zoom: number, width: number, height: number): string {
        if (!location) {
            throw new Error('No location provided');
        }
        let endpoint = 'https://maps.googleapis.com/maps/api/staticmap';

        let center = this.getCenter(location);

        let params = new URLSearchParams();
        params.append('center', center);
        params.append('zoom', zoom + '');
        params.append('size', width + 'x' + height);
        params.append('maptype', 'roadmap');
        params.append('markers', 'color:blue|label:' + location.name + '|' + location.latitude + ',' + location.longitude);
        params.append('key', this.config.googleMapsApiKey);

        let url = endpoint + '?' + params.toString();
        return url;
    }

    getMapUrl(location: Address): string {
        let endpoint = 'https://www.google.com/maps/search/';

        let center = this.getCenter(location);

        let params = new URLSearchParams();
        params.append('api', '1');
        params.append('query', center);

        let url = endpoint + '?' + params.toString();
        return url;
    }

    private getCenter(location: Address): string {
        let center = location.address ? location.address + ',' : '';
        center += location.city ? location.city + ',' : '';
        center += location.province ? location.province : '';
        return center;
    }

}