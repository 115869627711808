import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'tffl-core';

interface DialogData {
    user: User;
}

@Component({
	selector: 'app-login-notification',
	templateUrl: './login-notification.component.html',
	styleUrls: ['./login-notification.component.scss'],
	
})
export class LoginNotificationComponent implements OnInit {
    
    constructor(
        public dialogRef: MatDialogRef<LoginNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
    }
}
