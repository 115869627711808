import { Directive, Input, ElementRef, Renderer2, HostListener, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

@Directive({
    selector: '[fit-text]'
})
export class FitTextDirective {

    @Input() compression?= 1;
    @Input() activateOnResize?= true;
    @Input() minFontSize?: number | 'inherit' = 0;
    @Input() maxFontSize?: number | 'inherit' = Number.POSITIVE_INFINITY;
    @Input() delay?= 100;
    @Input() innerHTML;
    @Input() fontUnit?: 'px' | 'em' | string = 'px';

    @HostListener('window:resize')
    public onWindowResize = (): void => {
        if (this.activateOnResize) {
            this.recalculateFontSize.next();
        }
    };

    private fittextParent: HTMLElement;
    private fittextElement: HTMLElement;
    private fittextMinFontSize: number;
    private fittextMaxFontSize: number;
    private computed: CSSStyleDeclaration;
    private newlines: number;
    private lineHeight: string;
    private display: string;
    private calcSize = 10;
    private resizeTimeout: any;

    private recalculateFontSize = new Subject<number>();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) {
        this.fittextElement = el.nativeElement;
        this.fittextParent = this.fittextElement.parentElement;
        // console.log('fit text', el, el.nativeElement, this.fittextParent);
        this.computed = window.getComputedStyle(this.fittextElement);
        this.newlines = this.fittextElement.childElementCount > 0 ? this.fittextElement.childElementCount : 1;
        this.lineHeight = this.computed['line-height'];
        this.display = this.computed['display'];
        this.initFontSizeCalculation();
    }

    ngOnInit() {
        this.fittextMinFontSize = this.minFontSize === 'inherit' ? this.computed['font-size'] : this.minFontSize;
        this.fittextMaxFontSize = this.maxFontSize === 'inherit' ? this.computed['font-size'] : this.maxFontSize;
    }

    ngAfterViewInit() {
        this.recalculateFontSize.next(0);
    }

    ngOnDestroy() {
        this.recalculateFontSize.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['compression'] && !changes['compression'].firstChange) {
            this.recalculateFontSize.next(0);
        }
        if (changes['innerHTML']) {
            this.fittextElement.innerHTML = this.innerHTML;
            if (!changes['innerHTML'].firstChange) {
                this.recalculateFontSize.next(0);
            }
        }
    }

    private initFontSizeCalculation() {
        this.recalculateFontSize.pipe(
            throttleTime(this.delay, undefined, { leading: true, trailing: true }),
        ).subscribe(() => {
            if (this.fittextElement.offsetHeight * this.fittextElement.offsetWidth !== 0) {
                // reset to default
                this.setStyles(this.calcSize, 1, 'inline-block');
                // set new
                this.setStyles(this.calculateNewFontSize(), this.lineHeight, this.display);
            }
        });
    }

    // private setFontSize = (delay: number = this.delay): void => {
    //     this.resizeTimeout = setTimeout(
    //         (() => {
    //             console.log('resize');
    //             if (this.fittextElement.offsetHeight * this.fittextElement.offsetWidth !== 0) {
    //                 // reset to default
    //                 this.setStyles(this.calcSize, 1, 'inline-block');
    //                 // set new
    //                 this.setStyles(this.calculateNewFontSize(), this.lineHeight, this.display);
    //             }
    //         }).bind(this),
    //         delay
    //     );
    // };

    private calculateNewFontSize = (): number => {
        const ratio = (this.calcSize * this.newlines) / this.fittextElement.offsetWidth / this.newlines;

        return Math.max(
            Math.min(
                (this.fittextParent.offsetWidth -
                    (parseFloat(getComputedStyle(this.fittextParent).paddingLeft) +
                        parseFloat(getComputedStyle(this.fittextParent).paddingRight)) -
                    6) *
                ratio *
                this.compression,
                this.fittextMaxFontSize
            ),
            this.fittextMinFontSize
        );
    };

    private setStyles = (fontSize: number, lineHeight: number | string, display: string): void => {
        this.renderer.setStyle(this.fittextElement, 'fontSize', fontSize.toString() + this.fontUnit);
        this.renderer.setStyle(this.fittextElement, 'lineHeight', lineHeight.toString());
        this.renderer.setStyle(this.fittextElement, 'display', display);
    };

}
