import { NgModule } from '@angular/core';
import { MainModule } from './main/main.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TfflCoreModule, TfflCoreConfig, TfflClient } from 'tffl-core';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';
import { APP_CONFIG, TFFL_CONFIG } from './app.config';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeaguePagesService, LeaguePagesGuard } from './core';

const CORE_CONFIG: TfflCoreConfig = {
    apiBase: environment.apiBase,
    leagueId: environment.leagueId
}

const leaguePagesGuardFactory = (a: TfflClient, b: LeaguePagesService) => {
    return new LeaguePagesGuard(a, b, environment.leagueId);
};


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        MainModule,
        TfflCoreModule.forRoot(CORE_CONFIG),
        Angulartics2Module.forRoot({
            pageTracking: {
                clearQueryParams: true,
            },
            developerMode: environment.angulartics2developerMode
        }),
        // NgbModule,
    ],
    providers: [
        {
            provide: APP_CONFIG,
            useValue: TFFL_CONFIG
        },
        {
            provide: LeaguePagesGuard,
            useFactory: leaguePagesGuardFactory,
            deps: [TfflClient, LeaguePagesService]
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
