import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-dropdown-panel',
    templateUrl: './dropdown-panel.component.html',
    styleUrls: ['./dropdown-panel.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        // trigger('pageIn', Animations.pageInOut),
        trigger('expandArrow', [
            state('closed', style({
                marginBottom: '-0.6em',
            })),
            state('opened', style({
                marginBottom: '0.4em',
            })),
            transition('closed => opened', animate('400ms ease-in-out')),
            transition('opened => closed', animate('400ms ease-in-out'))
        ]),
        trigger('slideDown', [
            state('closed', style({
                height: '0px'
            })),
            state('opened', style({
                height: '*'
            })),
            transition('closed => opened', animate('400ms ease-in-out')),
            transition('opened => closed', animate('400ms ease-in-out'))
        ])
    ]
})
export class DropdownPanelComponent implements OnInit {

    @Input() status: 'closed' | 'opened' = 'closed';
    @Output() statusChanged = new EventEmitter<'closed' | 'opened'>();
    
    constructor() { }

    ngOnInit() {}

    angleIconClass() {
        if (this.status == 'closed') {
            return 'fa-angle-down';
        } else {
            return 'fa-angle-up';
        }
    }
    
    toggle() {
        this.toggleStatus();
        this.statusChanged.emit(this.status);
    }
    
    private toggleStatus() {
        this.status = this.status == 'closed' ? 'opened' : 'closed';
    }

}
