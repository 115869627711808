<!-- <app-header></app-header>
<div class="main-container">
    <div class="pb-5">
        <router-outlet class="position-absolute"></router-outlet>
    </div>
    <app-footer></app-footer>
</div> -->


<div id="app-container"
     [class.is-mobile]="mobileQuery.matches"
     [class.sidenav-open]="sidenav?.opened">
    <mat-toolbar class="tffl-bg-primary tffl-text-contrast-primary" #toolbar>
        <mat-toolbar-row class="top-toolbar container">
            <button mat-icon-button
                    (click)="sidenav.toggle()"
                    class="toggle-button"
                    *ngIf="mobileQuery.matches">
                <mat-icon>menu</mat-icon>
            </button>
            <a routerLink="/"
               mat-button>
                <img [src]="logoSrc"
                     alt="Toronto Flag Football League">
            </a>
            <div class="flex-actions"
                 *ngIf="!mobileQuery.matches">
                <a routerLink="/programs"
                   mat-button
                   color="primary-light">Register</a>
                <span *ngIf="!!user">
                    <button mat-raised-button
                            color="primary-light"
                            *ngIf="!!user"
                            class="ml-3"
                            [matMenuTriggerFor]="menu">
                        {{ user?.firstName || 'Profile' }}
                        <i class="fas fa-chevron-down"></i>
                    </button>
                    <mat-menu #menu="matMenu"
                              xPosition="before">
                        <ng-template matMenuContent>
                            <a mat-menu-item
                               routerLink="/dashboard">
                                <i class="fas fa-fw fa-user"></i> My Dashboard
                            </a>
                            <a mat-menu-item
                               routerLink="/staff"
                               *ngIf="hasStaffRegistration">
                                <i class="fas fa-fw fa-user-tie"></i> Staff Dashboard
                            </a>
                            <a mat-menu-item
                               routerLink="/cart/orders">
                                <i class="fas fa-fw fa-shopping-cart"></i> My Cart
                            </a>
                            <mat-divider></mat-divider>
                            <a mat-menu-item
                               [routerLink]="['/', 'players', user?.id]">
                                <i class="fas fa-fw fa-user"></i> <span class="strong">My</span> player page
                            </a>
                            <a mat-menu-item
                               [routerLink]="['/', 'players', u.id]"
                               *ngFor="let u of relatedUsers">
                                <i class="fas fa-fw fa-user"></i> <span class="strong">{{ u.name }}'s</span> player page
                            </a>
                            <mat-divider></mat-divider>
                            <a mat-menu-item
                               routerLink="/logout">
                                <i class="fas fa-fw fa-door-open"></i> Logout
                            </a>
                            <a mat-menu-item
                               routerLink="/admin/login"
                               *ngIf="isAdmin">
                                <i class="fas fa-fw fa-user-secret"></i> Admin Login
                            </a>
                        </ng-template>
                    </mat-menu>
                </span>
                <span *ngIf="!user">
                    <a mat-raised-button
                       color="primary-light"
                       class="ml-3"
                       routerLink="/login">
                        Login
                    </a>
                </span>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="!mobileQuery.matches"
                         class="tffl-bg-primary tffl-text-contrast-primary">
            <app-desktop-menu-bar [isAdmin]="isAdmin"
                                  class="container"></app-desktop-menu-bar>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-sidenav-container>
        <mat-sidenav #snav
                     mode="over"
                     fixedInViewport
                     fixedTopGap="66"
                     class="tffl-bg-primary tffl-text-contrast-primary"
                     *ngIf="mobileQuery.matches">
            <app-mobile-menu [isAdmin]="isAdmin"
                             [isStaff]="hasStaffRegistration"
                             [user]="user"
                             [relatedUsers]="relatedUsers"
                             (navigated)="snav.close()"></app-mobile-menu>
        </mat-sidenav>
        <mat-sidenav-content [style.top.px]="toolbar._elementRef.nativeElement.offsetHeight">
            <router-outlet></router-outlet>
            <!-- <app-upload-indicators></app-upload-indicators> -->
            <app-footer></app-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>