import { Component, Input, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Registration, RegistrationType } from 'tffl-core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
    registration: Registration;
}

@Component({
    selector: 'app-create-registration-notification',
    templateUrl: './create-registration-notification.component.html',
    styleUrls: ['./create-registration-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateRegistrationNotificationComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<CreateRegistrationNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
    }

    showReferral() {
        return [RegistrationType.PLAYER].includes(this.data.registration.registrationTypeId);
    }

    showCart() {
        return [RegistrationType.TEAM, RegistrationType.PLAYER].includes(this.data.registration.registrationTypeId);
    }

    showStaff() {
        return !this.showCart();
    }
}