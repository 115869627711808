import { Injectable } from '@angular/core';
import { NotificationType, TfflClient, TfflEventService, TfflEvent } from 'tffl-core';

@Injectable({
    providedIn: 'root'
})
export class ArgosOptinService {

    constructor(
        private tfflClient: TfflClient,
        private notificationService: TfflEventService
    ) { }

    // /**
    //  * @returns Observable<boolean> - whether the logged in user has opted into 
    //  * argos newsletters 
    //  */
    // listenForLoginsAndAskArgosOptin(): Observable<boolean> {
    //     return this.authService.getUserId().pipe(
    //         switchMap(userId => {
    //             return !userId ? of(null) : this.askArgosOptinIfNotBeenAsked(userId);
    //         })
    //     );
    // }

    askArgosOptinIfNotBeenAsked(userId: any): void {
        this.tfflClient.users.getUser(userId).subscribe(user => {
            if (!user.hasBeenAskedArgosOptin && !user.isArgosOptin) {
                this.notificationService.push(new TfflEvent(user, NotificationType.ArgosOptin));
            }
        });
    }
}
