<div *ngIf="program && program.id; then loadedProgram else blankProgram"></div>
<ng-template #loadedProgram>
    <div class="text-center text-uppercase">
        <div class="strong mb-1">
            {{program.season.name}} {{program.name}}
        </div>
        <div class="tffl-text-primary-light h5">
            {{program.ageYearsSentence}}
        </div>
        <div>
            {{program.startDate | date: 'MMM d'}} - {{program.endDate | date: 'MMM d' : 'UTC'}} // <br>
            {program.numberOfSessions, plural, =1 {1 Session} other {{{program.numberOfSessions}} Sessions }}
        </div>
    </div>
    <div *ngIf="program.isAtCapacity"
         class="mt-2 p-3 rounded tffl-bg-gray-50">
        <ng-container *ngIf="program.programTypeId == 'ADULT'; else youthSentence">
            This program is full at the moment. Please know that we continue to accept new team registrations and free
            agent registrations on a waiting list as we are actively working on the logistics of accepting more teams
            and adding more space. We ask for payment for waiting list teams/free agents so that we can seamlessly add
            you to the league when space is available. We will refund any teams if no space is available and any free
            agents that we could not place on a team.
        </ng-container>
        <ng-template #youthSentence>
            This program is full at the moment. Please know that we continue to accept registrations on a waiting list
            as we are actively working on the logistics of accepting more players and adding more space. We ask for
            payment for waiting list players so that we can seamlessly place you on a team when space is available. We
            will refund any players that we can not place on teams.
        </ng-template>
    </div>
</ng-template>
<ng-template #blankProgram>
    <div class="strong">
        <span class="d-inline-block bg-light line-height-1">{{ '&nbsp;' | repeat: 50 }}</span>
    </div>
    <div class="tffl-text-primary-light h5">
        <span class="d-inline-block bg-light line-height-1">{{ '&nbsp;' | repeat: 20 }}</span>
    </div>
    <div>
        <span class="d-inline-block bg-light line-height-1">
            {{ '&nbsp;' | repeat: 40 }}
        </span>
    </div>
</ng-template>