import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-main-not-found',
  templateUrl: './main-not-found.component.html',
  styleUrls: ['./main-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
