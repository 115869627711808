<a [routerLink]="urlTree"
   mat-raised-button>
    <span class="fa-stack fa-4x tffl-text-primary-100 icon">
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fas tffl-text-contrast-primary-100 fa-fw fa-running fa-stack-1x"></i>
    </span>
    <p class="sentence tffl-text-gray">
        <ng-content select="[not-found-helper-message]"></ng-content>
    </p>
    <div class="continue-badge tffl-bg-primary-light tffl-text-contrast-primary-light">
        <ng-content select="[not-found-action-message]"></ng-content>
    </div>
</a>