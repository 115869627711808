import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeaguePagesGuard } from './core';
import { MainNotFoundComponent } from './main/main-not-found/main-not-found.component';
import { TfflMainComponent } from './main/tffl-main/tffl-main.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'logout',
        loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutModule)
    },
    {
        path: '',
        component: TfflMainComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'staff',
                loadChildren: () => import('./pages/staff/staff.module').then(m => m.StaffModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'cart',
                loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule)
            },
            {
                path: 'programs',
                loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsModule)
            },
            {
                path: 'register',
                loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
            },
            {
                path: 'registrations',
                loadChildren: () => import('./pages/registrations/registrations.module').then(m => m.RegistrationsModule)
            },
            // {
            //     path: 'registrations',
            //     loadChildren: () => import('./pages/registrations/registrations.module').then(m => m.RegistrationsModule)
            // },
            {
                path: 'info',
                loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)
            },
            {
                path: 'attendance',
                loadChildren: () => import('./pages/attendance/attendance.module').then(m => m.AttendanceModule)
            },
            {
                path: 'games',
                loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
            },
            {
                path: 'teams',
                loadChildren: () => import('./pages/teams/teams.module').then(m => m.TeamsModule)
            },
            {
                path: 'players',
                loadChildren: () => import('./pages/players/players.module').then(m => m.PlayersModule)
            },
            {
                /**
                 * Legacy links that have been updated. Old emails still 
                 * reference these links
                 */
                path: 'league',
                children: [
                    { path: 'statistics', redirectTo: '/statistics' },
                    { path: 'schedule', redirectTo: '/schedule' },
                    { path: 'standings', redirectTo: '/standings' },
                    { path: 'attendance', redirectTo: '/attendance' },
                ]
            },
            {
                path: '',
                canActivate: [LeaguePagesGuard],
                children: [
                    {
                        path: 'standings',
                        loadChildren: () => import('./pages/standings/standings.module').then(m => m.StandingsModule)
                    },
                    {
                        path: 'statistics',
                        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule)
                    },
                    {
                        path: 'schedule',
                        loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.ScheduleModule)
                    },
                ]
            },
            {
                path: '**',
                component: MainNotFoundComponent
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { 
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
