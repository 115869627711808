import { Injectable } from '@angular/core';
import { DateHelper, Game, GamesFilter, GamesListFilter, LeagueFilter, Season, TfflClient } from 'tffl-core';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, tap, throttleTime } from 'rxjs/operators';
import { LeaguePagesService } from './league-pages.service';

@Injectable({ providedIn: 'root' })
export class SchedulePagesService {

    gamesChanged = new BehaviorSubject<GamesListFilter>(null);

    private gamesListFilter: GamesListFilter;

    constructor(
        private leaguePagesService: LeaguePagesService,
        private tfflClient: TfflClient,
    ) {
        this.init();
    }

    setDate(d: Date) {
        this.gamesListFilter.date = d;
        this.emitGames('emitting games because date was set: ' + d);
    }

    private init() {
        this.gamesListFilter = new GamesListFilter();

        this.leaguePagesService.seasonChanged.pipe(
            distinctUntilChanged(),
            filter(leagueFilter => !!leagueFilter),
        ).subscribe((leagueFilter: LeagueFilter) => {
            this.gamesListFilter.leagueFilter = leagueFilter;
            this.setSeason(leagueFilter.season);
        });

        merge(
            this.leaguePagesService.programsChanged,
            this.leaguePagesService.teamsChanged,
            this.leaguePagesService.divisionsChanged,
        ).pipe(
            throttleTime(100),
            filter(leagueFilter => !!leagueFilter),
        ).subscribe((leagueFilter: LeagueFilter) => {
            // console.log('schedule changed', leagueFilter);
            this.gamesListFilter.leagueFilter = leagueFilter;
            this.updateDate();
            this.emitGames('emitting games because the LeaguePagesService emmitted a programs/teams/divisions change');
        })
    }

    private setSeason(season: Season) {
        this.loadGames(season).subscribe(_ => {
            this.updateDate();
            this.emitGames('Emitting games becasue the season was set: ' + (season ? season.id : '(No id)'));
        });
    }

    private updateDate() {
        let currentD = this.gamesListFilter.date;
        let gameDays = this.gamesListFilter.getDays();
        this.gamesListFilter.date = this.getInitialDate(currentD, gameDays);
    }

    private emitGames(debugReason?: string) {
        this.gamesChanged.next(this.gamesListFilter);
        console.log('SchedulePagesService is Emitting games because: ', debugReason);
    }

    private loadGames(season: Season): Observable<Game[]> {

        if (!season) {
            return of([]);
        }

        let gamesFilter = new GamesFilter();
        gamesFilter.seasonId = season.id;
        return this.tfflClient.games.getGameStream(gamesFilter).pipe(
            // tap(games => console.log('loaded new games', games)),
            tap(games => this.gamesListFilter.allGames = games),
        );
    }

    /**
     * Whenever the list of games changes (because a different team is 
     * selected, for example) we need to update the date of the 
     * GamesListFilter so that the date falls on a valid game day. Ideally,
     * we also pick a date that is close to the currently viewed date.
     */
    private getInitialDate(currentDate: Date, gameDays: Date[]): Date {

        let newInitialDate: Date = currentDate || new Date();
        return this.getClosestNextDate(newInitialDate, gameDays);
    }

    private getClosestNextDate(d: Date, days: Date[]): Date {

        if (!days.length) {
            return null;
        }

        if (d == null) {
            return days[0];
        }

        if (DateHelper.isFutureDate(d, days[0])) {
            return days[0];
        }

        for (let i = 0; i < days.length - 1; i++) {
            let prevDay = days[i];
            let nextDay = days[i + 1];

            if (DateHelper.isSameDate(d, prevDay) ||
                DateHelper.isSameDate(d, nextDay)) {
                return d;
            }

            /**
             * If the date is between the two days, take the next day
             */
            if (prevDay < d && d < nextDay) {
                return nextDay;
            }
        }

        /**
         * If we get here, then the date is past the last day of games
         */
        return days[days.length - 1];
    }
}
