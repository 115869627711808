import { Component, OnInit, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Team } from 'tffl-core';

interface DialogData {
    team: Team;
}

@Component({
    selector: 'app-sent-team-message-notification',
    templateUrl: './sent-team-message-notification.component.html',
    styleUrls: ['./sent-team-message-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SentTeamMessageNotificationComponent implements OnInit {
    
    constructor(
        public dialogRef: MatDialogRef<SentTeamMessageNotificationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit() {
    }
}
