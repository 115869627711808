import { NgModule } from '@angular/core';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { TfflComponentsModule } from 'tffl-components';
import { DropdownPanelComponent } from './components/dropdown-panel/dropdown-panel.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RepeatPipe } from './pipes/repeat.pipe';
import { SelectedProgramHeaderComponent } from './components/selected-program-header/selected-program-header.component';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { TimePipe } from './pipes/time.pipe';
import { FeetAndInchesPipe } from './pipes/feet-and-inches.pipe';
import { FitTextDirective } from './directives/fit-text.directive';

@NgModule({
    declarations: [
        NotFoundComponent,
        PageNotFoundComponent,
        PageHeaderComponent,
        BackButtonDirective,
        DropdownPanelComponent,
        RepeatPipe,
        SelectedProgramHeaderComponent,
        OrdinalPipe,
        TimePipe,
        FeetAndInchesPipe,
        FitTextDirective,
    ],
    imports: [
        TfflComponentsModule,
        // NgbModule,
    ],
    exports: [
        NotFoundComponent,
        PageNotFoundComponent,
        PageHeaderComponent,
        BackButtonDirective,
        // NgbModule,
        DropdownPanelComponent,
        InfiniteScrollModule,
        RepeatPipe,
        TimePipe,
        SelectedProgramHeaderComponent,
        OrdinalPipe,
        FeetAndInchesPipe,
        FitTextDirective,
    ],
    providers: [
        OrdinalPipe,
    ]
})
export class SharedModule { }
