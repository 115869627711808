import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { AuthService, DateHelper, Division, League, LeagueFilter, Program, Registration, RegistrationsFilter, Season, Team, TeamsFilter, TfflClient } from 'tffl-core';

@Injectable({ providedIn: 'root' })
export class LeaguePagesService {

    seasonChanged = new BehaviorSubject<LeagueFilter>(null);
    programsChanged = new BehaviorSubject<LeagueFilter>(null);
    teamsChanged = new BehaviorSubject<LeagueFilter>(null);
    divisionsChanged = new BehaviorSubject<LeagueFilter>(null);

    private leagueFilter: LeagueFilter;
    private allSeasons: Season[];
    private seasonsLoaded$ = new ReplaySubject(1);
    private prevSeasonId = null;

    constructor(
        private tfflClient: TfflClient,
        private authService: AuthService,
    ) {
    }

    /**
     * This function must get called somewhere for this service to work
     */
    setSeasons(seasons: Season[]): void {
        this.allSeasons = seasons;
        this.seasonsLoaded$.next();
    }

    setSeasonId(seasonId: any): Observable<any> {

        /** No need to reload everything if the seasonId has not changed */
        if (seasonId == this.prevSeasonId) {
            this.seasonChanged.next(this.leagueFilter);
            return of(this.leagueFilter);
        }

        this.prevSeasonId = seasonId;

        return this.getSeasonById(seasonId).pipe(
            switchMap(season => this.setSeason(season))
        );
    }

    setSelectedTeams(teams: Team[]) {
        this.leagueFilter.teams = teams;
        this.teamsChanged.next(this.leagueFilter);
        this.saveLeaguePreferences(this.leagueFilter);
    }

    setSelectedDivisions(divisions: Division[]) {
        this.leagueFilter.divisions = divisions;
        this.divisionsChanged.next(this.leagueFilter);
        this.saveLeaguePreferences(this.leagueFilter);
    }

    setSelectedPrograms(programs: Program[]) {
        this.leagueFilter.programs = programs;
        this.programsChanged.next(this.leagueFilter);
        this.saveLeaguePreferences(this.leagueFilter);
    }

    private setSeason(season: Season): Observable<any> {
        return this.loadSeason(season).pipe(
            switchMap(result => this.loadLeaguePreferences({ league: result.league, season, allPrograms: result.programs, allTeams: result.teams, allDivisions: result.divisions })),
            tap(leagueFilter => {
                this.leagueFilter = leagueFilter;
                this.leagueFilter.allSeasons = this.allSeasons;
                this.leagueFilter.season = season;
                this.seasonChanged.next(this.leagueFilter);
                this.programsChanged.next(this.leagueFilter);
                this.teamsChanged.next(this.leagueFilter);
                this.divisionsChanged.next(this.leagueFilter);
            })
        );
    }

    private loadSeason(season: Season): Observable<{ league: League, programs: Program[], teams: Team[], divisions: Division[] }> {
        return forkJoin(
            this.loadLeague(season),
            this.loadPrograms(season).pipe(
                switchMap(
                    (programs: Program[]) => this.loadTeams(programs),
                    (outerValue, innerValue) => ({
                        programs: outerValue,
                        teams: innerValue
                    })
                ),
            ),
            this.loadDivisions(season)
        ).pipe(
            map(
                ([leagueResult, programsResult, divisionsResult]) => ({
                    league: leagueResult,
                    programs: programsResult.programs,
                    teams: programsResult.teams,
                    divisions: divisionsResult
                })
            )
        );
    }

    private loadLeague(season: Season): Observable<League> {
        if (!season) {
            return of(null);
        }
        return this.tfflClient.leagues  .getLeague(season.leagueId);
    }

    private loadPrograms(season: Season): Observable<Program[]> {
        if (!season) {
            return of([]);
        }

        return this.tfflClient.programs .getBySeason(season.id);
    }

    private loadTeams(programs: Program[]): Observable<Team[]> {

        let teamObs: Observable<Team[]>;

        if (!programs.length) {
            teamObs = of([]);
        } else {
            let programIds = programs.map(p => p.id);
            let filter = new TeamsFilter({
                programIds: programIds
            });
            teamObs = this.tfflClient.teams .getTeams(filter);
        }

        return teamObs;
    }

    private loadDivisions(season: Season): Observable<Division[]> {

        let divisionsObs: Observable<Division[]>;

        if (!season) {
            divisionsObs = of([]);
        } else {
            let seasonId = season.id;
            divisionsObs = this.tfflClient.programs .getDivisions(seasonId);
        }

        return divisionsObs;
    }

    /**
     * Whenever the list of games changes (because a different team is 
     * selected, for example) we need to update the date of the 
     * GamesListFilter so that the date falls on a valid game day. Ideally,
     * we also pick a date that is close to the currently viewed date.
     */
    private getInitialDate(currentDate: Date, gameDays: Date[]): Date {
        let newInitialDate: Date = currentDate || new Date();
        return this.getClosestNextDate(newInitialDate, gameDays);
    }

    private getClosestNextDate(d: Date, days: Date[]): Date {

        if (!days.length) {
            return null;
        }

        if (d == null) {
            return days[0];
        }

        if (DateHelper.isFutureDate(d, days[0])) {
            return days[0];
        }

        for (let i = 0; i < days.length - 1; i++) {
            let prevDay = days[i];
            let nextDay = days[i + 1];

            if (DateHelper.isSameDate(d, prevDay) ||
                DateHelper.isSameDate(d, nextDay)) {
                return d;
            }

            /**
             * If the date is between the two days, take the next day
             */
            if (prevDay < d && d < nextDay) {
                return nextDay;
            }
        }

        /**
         * If we get here, then the date is past the last day of games
         */
        return days[days.length - 1];
    }

    private loadLeaguePreferences(
        { league, season, allPrograms, allTeams, allDivisions }: { league: League; season: Season; allPrograms: Program[]; allTeams: Team[]; allDivisions: Division[]; }): Observable<LeagueFilter> {

        // console.log({
        //     message: 'load league preferences',
        //     season: season,
        //     allPrograms: allPrograms,
        //     allTeams: allTeams,
        //     allDivisions: allDivisions,
        // })

        try {
            const preferencesString = localStorage.getItem('leaguePreferences');
            let preferences = JSON.parse(preferencesString);

            if (preferences.season.id != season.id) {
                throw new Error('preferences for season id was not set');
            }
            let leagueFilter = new LeagueFilter({
                league: league,
                season: season,
                allPrograms: allPrograms,
                allTeams: allTeams,
                allDivisions: allDivisions,
                programs: preferences.programs.map(item => Program.fromServer(item)),
                teams: preferences.teams.map(item => Team.fromServer(item)),
                divisions: preferences.divisions.map(item => Division.fromServer(item))
            });

            return of(leagueFilter);
        } catch (e) {
            return this.loadInitialLeagueFilter({ league, season, allPrograms, allTeams, allDivisions }).pipe(
                tap(preferences => this.saveLeaguePreferences(preferences))
            );
        }
    }

    private saveLeaguePreferences(filter: LeagueFilter): void {
        let saveData = {
            league: !filter.league ? null : {
                id: filter.league.id,
                name: filter.league.name
            },
            programs: filter.programs.map(item => ({
                id: item.id,
                name: item.name,
            })),
            teams: filter.teams.map(item => ({
                id: item.id,
                name: item.name,
                divisionId: item.divisionId,
                programId: item.programId
            })),
            season: {
                id: filter.season.id
            },
            divisions: filter.divisions.map(item => ({
                id: item.id,
                name: item.name,
                programName: item.programName,
                programId: item.programId
            })),
        }

        localStorage.setItem('leaguePreferences', JSON.stringify(saveData));
    }

    private loadInitialLeagueFilter(
        { league, season, allPrograms, allTeams, allDivisions }: { league: League; season: Season; allPrograms: Program[]; allTeams: Team[]; allDivisions: Division[]; }): Observable<LeagueFilter> {

        return this.authService.getUserId().pipe(
            first(),
            switchMap(userId => userId ? this.loadUserRegistrations(userId, season.id) : of([])),
            map(registrations => {
                let leagueFilter = new LeagueFilter({
                    league: league,
                    season: season,
                    allPrograms: allPrograms,
                    allDivisions: allDivisions,
                    allTeams: allTeams,
                    programs: allPrograms.filter(
                        p => registrations.some(r => r.programId == p.id)
                    ),
                    teams: allTeams.filter(
                        t => registrations.some(r => r.teamId == t.id)
                    ),
                    divisions: allDivisions.filter(
                        d => registrations.some(r => r.team && r.team.divisionId == d.id)
                    )
                });

                return leagueFilter;
            })
        );
    }

    private loadUserRegistrations(userId: any, seasonId: any): Observable<Registration[]> {
        let filter = new RegistrationsFilter({
            relatedToUserId: userId,
        });
        return this.tfflClient.registrations    .getRegistrations(filter).pipe(
            map(regs => regs.filter(r => r.seasonId == seasonId)),
        );
    }

    private getSeasonById(seasonId): Observable<Season> {

        return this.seasonsLoaded$.pipe(
            first(),
            map(() => {
                let season = this.allSeasons.find(s => s.id == seasonId);

                if (!season) {
                    console.error('No season with id: ' + seasonId, this.allSeasons);
                    throw new Error('No season with id: ' + seasonId);
                }

                return season;
            })
        );

    }
}
