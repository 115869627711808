// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,//facebook pixel needs this to be true for events to fire
    googleMapsApiKey: 'AIzaSyB98PnXeE0zuNKB9TbUoRHScyZQyuxQ-l4',
    apiBase: 'https://dev-api.torontoflagfootball.com',
    // apiBase: 'http://localhost:8888',
    statsAppUrl: '//dev-stats.torontoflagfootball.com',
    leagueId: '1',
    stripeKey: 'pk_test_Gf9OBWf4hPO6BgRCdKeyIEFh',
    angulartics2developerMode: true, //affects facebook pixel, google
    isDriftOn: false, //whether drift is turned on
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
