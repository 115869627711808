import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { delay, filter, first, map, tap } from 'rxjs/operators';
import { AuthService, DriftService, LeagueWaiverService, TfflClient } from 'tffl-core';
import { AppConfig, APP_CONFIG } from './app.config';
import { ArgosOptinService } from './core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

    constructor(
        private tfflClient: TfflClient,
        private angulartics2Facebook: Angulartics2Facebook,
        private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private angulartics2: Angulartics2,
        private authService: AuthService,
        private argosOptinService: ArgosOptinService,
        private driftService: DriftService,
        private router: Router,
        private route: ActivatedRoute,
        private leagueWaiverService: LeagueWaiverService,
        private titleService: Title,
        @Inject(APP_CONFIG) private config: AppConfig,
    ) {
        this.initSettings();
    }

    initSettings() {

        /**
         * If the user is already logged in, show them the argos opt in message
         */
        if (this.authService.isLoggedIn()) {
            this.authService.getUserId().pipe(
                first(),
                delay(2000),
                tap(userId => this.argosOptinService.askArgosOptinIfNotBeenAsked(userId)),
            ).subscribe();
        }

        // this.argosOptinService.listenForLoginsAndAskArgosOptin()
        //     .subscribe((isOptin: boolean) => { });

        // this.angulartics2Facebook.startTracking();
        this.angulartics2GoogleTagManager.startTracking();
        // this.angulartics2GoogleAnalytics.startTracking();

        this.angulartics2.eventTrack.subscribe(eventTrack => {
            console.log('eventTrack', eventTrack);
        });

        if (this.config.isDriftOn) {
            this.driftService.init();
        }

        /**
         * Initialize the league data
         */
        this.tfflClient.leagues.getLeague(this.config.leagueId).subscribe(league => {
            this.leagueWaiverService.setData({ league });
        });

        /**
         * Update the page title when the route changes
         */
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route.snapshot),
            map(route => {
                let pageTitle = route && route.data && route.data.pageTitle;
                while (route.firstChild) {
                    route = route.firstChild;
                    if (route.data && route.data.pageTitle) {
                        pageTitle = route.data.pageTitle;
                    }
                };
                return pageTitle;
            }),
        ).subscribe(title => {
            console.log('PAGE TITLE:', title);
            this.titleService.setTitle(title || 'Toronto Flag Football League');
        });
    }
}
