<h1 mat-dialog-title
    class="text-center">Joined Team</h1>
<div mat-dialog-content>
    <div class="text-center mb-3">
        <div class="tffl-text-primary">
            <i class="fas fa-4x fa-check-circle"></i>
        </div>
        <div class="h3 mt-1">
            Success
        </div>
    </div>
    <div class="text-center mb-3">
        <div>
            <span class="strong">Next step:</span>
        </div>
        <div>
            <button mat-button
                    color="primary-light" 
                    class="px-0"
                    [mat-dialog-close]="false">Invite some friends to join you.</button>
        </div>
        <div>
            Also, one of your teammates must pay for the registration fee if it has not been paid.
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button
            [mat-dialog-close]="false"
            cdkFocusInitial>Continue</button>
</div>