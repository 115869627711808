import { MediaMatcher } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AuthService, TfflClient, User } from 'tffl-core';

@UntilDestroy()
@Component({
    selector: 'app-tffl-main',
    templateUrl: './tffl-main.component.html',
    styleUrls: ['./tffl-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TfflMainComponent implements OnInit {

    @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;

    name: string;
    isAdmin: boolean;
    hasStaffRegistration: boolean;
    user: User;
    relatedUsers: User[] = [];
    logoSrc: string; /** Changes based on mobile/desktop */
    mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;

    constructor(
        private cdr: ChangeDetectorRef,
        private media: MediaMatcher,
        private router: Router,
        private auth: AuthService,
        private tfflClient: TfflClient,
        private overlayContainer: OverlayContainer,

    ) {
        this.mobileQuery = this.media.matchMedia('(max-width: 769px)');

        this._mobileQueryListener = () => {

            /**
             * If the sidenav is opened when the page goes from mobile to 
             * desktop, close it
             */
            if (!this.mobileQuery.matches && this.sidenav.opened) {
                this.sidenav.close();
            }

            this.updateLogo();
            cdr.markForCheck();
        }
        this.mobileQuery.addListener(this._mobileQueryListener);

        /**
         * Update logo the first time
         */
        this.updateLogo();
    }

    ngOnInit() {
        const userChanges = this.auth.getUser().pipe(
            untilDestroyed(this),
            shareReplay(1)
        );

        userChanges.subscribe(user => {
            this.user = user;
            this.isAdmin = this.auth.isAdmin();
            this.cdr.markForCheck();
        });

        userChanges.pipe(
            switchMap(user => {
                if (!user) return [];
                return this.tfflClient.users.getRelatedUsers(user.id);
            }),
        ).subscribe(users => {
            this.relatedUsers = users;
            this.cdr.markForCheck();
        });

        /**
         * Determine whether the user is a 
         */
        userChanges.pipe(
            switchMap(user => {
                if (!user) return of(false);
                return this.tfflClient.registrations.hasStaffRegistration(user.id);
            }),
        ).subscribe(hasStaffRegistration => {
            this.hasStaffRegistration = hasStaffRegistration;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    private updateLogo() {
        if (this.mobileQuery.matches) {
            this.logoSrc = '//s3.amazonaws.com/tffl/logos/tffl-and-argos-logo-white.png';
        } else {
            // this is the old non-nfl logo. To restore this, you'll need to update the css for img too
            // this.logoSrc = '//s3.amazonaws.com/tffl/logos/tffl-and-argos-logo-blue.png';
            this.logoSrc = 'https://tffl.s3.amazonaws.com/logos/tffl-argos-nfl-blue.png';
        }
    }

}
